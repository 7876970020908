.generated-licenses-label {
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.2rem; }

.generated-licenses-value {
  font-size: 1.2rem; }

.numberOfLicensesByPackage > div > input {
  border: 0 !important;
  padding-left: 0 !important; }
